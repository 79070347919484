import React from "react";

const RefugeeApeal = () => {
  return (
    <div>
      <div className="mt-4 text-gray-700">
        <p>
          If you have a failed refugee claim, our team is here to provide you
          with the guidance and support you need to navigate the Refugee Appeal
          Division (RAD) effectively.
        </p>
      </div>

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-2 lg:px-1 lg:py-2 mt-10">
        <div className="grid gap-8 row-gap-5 mb-8 md:row-gap-8 lg:grid-cols-4 sm:grid-cols-2">
          <div className="duration-300 transform bg-white border-l-4 border-primary hover:-translate-y-2">
            <div className="h-full p-5 border border-l-0 rounded-r shadow-sm">
              <h6 className="mb-2 font-semibold leading-5 text-black">
                Assessing Your Case
              </h6>
              <p className="text-sm text-gray-900">
                We will carefully assess your case to determine the grounds for
                your appeal. We will review the reasons for the refusal and work
                with you to build a strong appeal strategy.
              </p>
            </div>
          </div>
          <div className="duration-300 transform bg-white border-l-4 border-primary hover:-translate-y-2">
            <div className="h-full p-5 border border-l-0 rounded-r shadow-sm">
              <h6 className="mb-2 font-semibold leading-5 text-black">
                Preparing the Appeal
              </h6>
              <p className="text-sm text-gray-900">
                We will assist you in gathering additional evidence and
                documentation to support your appeal. Our team will ensure that
                your appeal package is comprehensive and compelling,
                highlighting the key factors that support your claim for refugee
                protection.
              </p>
            </div>
          </div>
          <div className="duration-300 transform bg-white border-l-4 border-primary hover:-translate-y-2">
            <div className="h-full p-5 border border-l-0 rounded-r shadow-sm">
              <h6 className="mb-2 font-semibold leading-5 text-black">
                Legal Representation
              </h6>
              <p className="text-sm text-gray-900">
                Navigating the refugee appeal process requires expertise in
                immigration law. We will advocate for your rights and present
                your case effectively before the Refugee Appeal Division.
              </p>
            </div>
          </div>
          <div className="duration-300 transform bg-white border-l-4 border-primary hover:-translate-y-2">
            <div className="h-full p-5 border border-l-0 rounded-r shadow-sm">
              <h6 className="mb-2 font-semibold leading-5 text-black">
                The Appeal Process
              </h6>
              <p className="text-sm text-gray-900">
                We will guide you through each step of the appeal process, from
                preparing the Notice of Appeal to attending the hearing before
                the Refugee Appeal Division if required. Our team will ensure
                that you are well-prepared and supported throughout the entire
                process.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="text-center">
          <a
            href="/book-appointment"
            className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 shadow-md md:w-auto bg-primary focus:shadow-outline focus:outline-none"
          >
            Book
          </a>
        </div> */}
      </div>

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-1">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-primary">
              <svg className="text-white w-7 h-7" viewBox="0 0 24 24">
                <polyline
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  points=" 8,5 8,1 16,1 16,5"
                  strokeLinejoin="round"
                />
                <polyline
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  points="9,15 1,15 1,5 23,5 23,15 15,15"
                  strokeLinejoin="round"
                />
                <polyline
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  points="22,18 22,23 2,23 2,18"
                  strokeLinejoin="round"
                />
                <rect
                  x="9"
                  y="13"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  width="6"
                  height="4"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Let us handle <br className="hidden md:block" />
                your{" "}
                <span className="inline-block text-primary">
                  immigration
                </span>{" "}
                journey
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                Ready to appeal your refugee claim before RAD? Contact
                AbaNorbert today to schedule a consultation.
              </p>
            </div>
            <div>
              <a
                href="/contact-us"
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 bg-primary p-3 text-white"
              >
                Contact us
                <svg
                  className="inline-block w-3 ml-2"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <div className="flex flex-col items-end px-3">
              <img
                className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_1000/vqgcqkhu9fbwr7lh511g"
                loading="lazy"
              />
              <img
                className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_1000/canada8_1_hmufch"
                loading="lazy"
              />
            </div>
            <div className="px-3">
              <img
                className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_1000/canada13_suh0hl"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefugeeApeal;
