import React from "react";

const TopHeader = () => {
  return (
    <div className="bg-primary text-white flex justify-between items-center p-4 lg:px-24 lg:flex topHeader">
      <div className="flex items-center space-x-2">
        <i className="fas fa-clock"></i>
        <span className="font-bold">Working Hours :</span>
        <span>Monday - Friday, 9am - 06pm</span>
        {/* <span className="font-bold">Location :</span>
        <span>100 Boulevard Alexis-Nihon, Saint-Laurent, QC, H4M 2P1 Office Suite# 595</span> */}
      </div>
      <div className="flex items-center space-x-4">
        <a href="mailto:info@abanorbert.ca" className="hover:underline">
          info@abanorbert.ca
        </a>
        <span>|</span>
        <a href="tel:+1 (514) 568-7173" className="hover:underline">
          +1 (514) 568-7173
        </a>
        <span>|</span>
        <div className="flex space-x-2">
          <a href="https://www.facebook.com/profile.php?id=61555828269284" className="hover:underline">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://x.com/Abanorberts" className="hover:underline">
            <i className="fab fa-x-twitter"></i>
          </a>
          <a href="https://www.instagram.com/abanorbertimmigration/" className="hover:underline">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/104547930/admin/feed/posts/?feedType=following" className="hover:underline">
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
