import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, DialogPanel } from "@headlessui/react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import abanologo from "../assets/abanlogo.png";
import useScroll from "../Hooks/useScroll";
import "../Styles/Navbar.css";

const navigation = [
  { name: "Home", href: "/" },
  { name: "About us", href: "/about-us" },
  {
    name: "Services",
    href: "/services",
    subLinks: [
      { name: "Refugee Claims", href: "/services/refugee-claims" },
      { name: "Refugee Appeal", href: "/services/refugee-appeal" },
      {
        name: "Pre-Removal Risk Assessment",
        href: "/services/pre-removal-risk-assessment",
      },
      {
        name: "Humanitarian and Compassionate Consideration",
        href: "/services/humanitarian-and-compassionate-consideration",
      },
      { name: "Detention Reviews", href: "/services/detention-reviews" },
      { name: "Family Sponsorship", href: "/services/family-sponsorship" },
      { name: "Express Entry", href: "/services/express-entry" },
      { name: "Other Services", href: "/services/other-services" },
    ],
  },
  { name: "Contact us", href: "/contact-us" },
  { name: "Pay now", href: "/pay-now" },
];

const NavBar = () => {
  const { color } = useScroll();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(location.pathname);

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleClick = (e, item) => {
    if (item.subLinks) {
      e.preventDefault();
      navigate(item.href); // Navigate to the services page
      setDropdownOpen(true); // Open the dropdown
    }
  };

  const handleSubLinkClick = (href) => {
    setActiveTab(href);
    setDropdownOpen(false); // Close the dropdown
  };

  const isActive = (href) => {
    if (href === "/") {
      return activeTab === href;
    }
    return activeTab.startsWith(href);
  };

  return (
    <div>
      <header
        className={`absolute header inset-x-0 top-0 z-50 ${
          color ? "navbar-scroll" : ""
        }`}
      >
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <img className="h-10 w-auto" src={abanologo} loading="lazy" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12 text-white">
            {navigation.map((item) => (
              <div
                key={item.name}
                className="relative"
                onMouseEnter={() => item.subLinks && handleMouseEnter()}
                onMouseLeave={() => item.subLinks && handleMouseLeave()}
              >
                <a
                  href={item.href}
                  className={`text-md font-semibold leading-6 flex items-center ${
                    isActive(item.href) ? "text-primary" : ""
                  }`}
                  onClick={(e) => handleClick(e, item)}
                >
                  {item.name}
                  {item.subLinks &&
                    (dropdownOpen ? (
                      <MdKeyboardArrowUp className="ml-1" />
                    ) : (
                      <MdKeyboardArrowDown className="ml-1" />
                    ))}
                </a>
                {item.subLinks && dropdownOpen && (
                  <div className="absolute left-0 top-full mt-2 w-64 bg-white rounded-md shadow-lg z-50 p-5">
                    {item.subLinks.map((subLink) => (
                      <a
                        key={subLink.name}
                        href={subLink.href}
                        className={`block px-0 py-2 text-gray-800 hover:bg-gray-100 ${
                          isActive(subLink.href) ? "text-primary" : ""
                        }`}
                        onClick={() => handleSubLinkClick(subLink.href)}
                      >
                        {subLink.name}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              href="/book-appointment"
              className="text-sm font-semibold leading-6 bg-primary text-white px-4 py-2"
            >
              Book Appointment
            </a>
          </div>
        </nav>
        <Dialog
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-8 w-auto" src={abanologo} alt="logo" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-900 "
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <div key={item.name} className="relative">
                      <a
                        href={item.href}
                        className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                          isActive(item.href) ? "text-primary" : ""
                        }`}
                        onClick={() => {
                          setActiveTab(item.href);
                          setMobileMenuOpen(false);
                        }}
                      >
                        {item.name}
                      </a>

                      {item.subLinks && (
                        <>
                          <button
                            className="flex items-center text-gray-900 hover:bg-gray-50"
                            onClick={() =>
                              setMobileDropdownOpen((prev) => !prev)
                            }
                          >
                            {mobileDropdownOpen ? (
                              <MdKeyboardArrowUp className="ml-1" />
                            ) : (
                              <MdKeyboardArrowDown className="ml-1" />
                            )}
                          </button>
                          {mobileDropdownOpen && (
                            <div className="ml-4 space-y-2">
                              {item.subLinks.map((subLink) => (
                                <a
                                  key={subLink.name}
                                  href={subLink.href}
                                  className={`block px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                                    isActive(subLink.href) ? "text-primary" : ""
                                  }`}
                                  onClick={() => {
                                    setActiveTab(subLink.href);
                                    setMobileMenuOpen(false); // close the dialog
                                    setMobileDropdownOpen(false); // Optionally close the dropdown as well
                                  }}
                                >
                                  {subLink.name}
                                </a>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="/book-appointment"
                    className="-mx-3text-sm font-semibold leading-6 bg-primary text-white px-4 py-3 text-base"
                  >
                    Book Appointment
                  </a>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
    </div>
  );
};

export default NavBar;
