import React, { useEffect, useState } from 'react';
import '../Styles/ScrollToTop.css'
import useScroll from '../Hooks/useScroll';

const ScrollToTopButton = () => {
  const { showScrollToTop, scrollToTop } = useScroll();

  return (
    <button
      onClick={scrollToTop}
      title="Go To Top"
      className={`fixed z-50 bottom-10 right-10 p-4 border-0 w-14 h-14 rounded-full shadow-md bg-primary text-white text-lg font-semibold transition-opacity duration-300 ${
        showScrollToTop ? 'opacity-100 fade-in' : 'opacity-0'
      }`}
      style={{ transition: 'opacity 0.3s, transform 0.3s' }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
        <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
      </svg>
      <span className="sr-only">Go to top</span>
    </button>
  );
};

export default ScrollToTopButton;
