import {
  Bars3Icon,
  XMarkIcon,
  ClockIcon,
  FaceSmileIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Unmatched Efficiency",
    description:
      "Experience the swiftness of our services as we work with you throughout your application process, saving you valuable time. With our streamlines approach, your journey from start to  finish will be a seamless and hustle-free experience.",
    icon: ClockIcon,
  },
  {
    name: "Elevating Customer Satisfaction",
    description:
      "Our paramount goal is your satisfaction. We take pride in curating an exceptional experience tailored to your needs, ensuring a seamless journey through the application process with unparalleled efficiency and care.",
    icon: FaceSmileIcon,
  },
  {
    name: "Demonstrating a Track Record of Success",
    description:
      "At AbaNorbert, our proven track record of successful immigration cases and glowing client testimonials demonstrate our competence and reliability in navigating complex immigration law, ensuring top-notch service for our clients.",
    icon: ChartBarIcon,
  },
];

export default features;
