import React from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import RefugeeClaims from "./RefugeeClaims";
import RefugeeApeal from "./RefugeeApeal";
import Risk from "./Risk";
import Humanitarian from "./Humanitarian";
import ExpressEntry from "./ExpressEntry";
import Detention from "./Detention";
import OtherServices from "./OtherServices";
import Family from "./Family";
import canad12 from "../assets/canad12.jpg" 

const services = [
  { name: "Refugee Claims", path: "refugee-claims" },
  { name: "Refugee Appeal", path: "refugee-appeal" },
  { name: "Pre-Removal Risk Assessment", path: "pre-removal-risk-assessment" },
  {
    name: "(H&C)",
    path: "humanitarian-and-compassionate-consideration",
  },
  { name: "Detention Reviews", path: "detention-reviews" },
  { name: "Family Sponsorship", path: "family-sponsorship" },
  { name: "Express Entry", path: "express-entry" },
  { name: "Other Services", path: "other-services" },
];

const ServiceContent = ({ service }) => {
  switch (service) {
    case "Refugee Claims":
      return (
        <div>
          <RefugeeClaims />
        </div>
      );
    case "Refugee Appeal":
      return (
        <div>
          <RefugeeApeal />
        </div>
      );
    case "Pre-Removal Risk Assessment":
      return (
        <div>
          <Risk />
        </div>
      );
    case "(H&C)":
      return (
        <div>
          <Humanitarian />
        </div>
      );
    case "Detention Reviews":
      return (
        <div>
          <Detention />
        </div>
      );
    case "Family Sponsorship":
      return (
        <div>
          <Family />
        </div>
      );
    case "Express Entry":
      return (
        <div>
          <ExpressEntry />
        </div>
      );
    case "Other Services":
      return (
        <div>
          <OtherServices />
        </div>
      );
    default:
      return null;
  }
};

const Claims = () => {
  const location = useLocation();
  const activeServicePath = location.pathname.split("/").pop();
  const activeService =
    services.find((service) => service.path === activeServicePath)?.name ||
    services[0].name;

  return (
    <div>
      <div className="relative hero-container">
        <img src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_2000/canad12_x0rjzp" loading="lazy" className="hero-image" />
        <div className="absolute inset-0 bg-black opacity-70"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white p-4">
          <h1 className="text-4xl font-bold text-center uppercase">
            {activeService}
          </h1>
          <div className="h-1 w-20 bg-primary mt-2 mb-2"></div>
          <p className="text-xl text-center">AbaNorbert Immigration Services</p>
        </div>
      </div>

      <section className="flex flex-col lg:flex-row py-5 px-0">
        <div className="w-full lg:w-3/4 p-4">
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-2xl font-bold">{activeService} Services</h1>
            <div className="mt-4 text-gray-700">
              <ServiceContent service={activeService} />
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/4 bg-gray-100 p-4 lg:p-6 lg:border-l-2 lg:border-gray-300">
          <nav className="space-y-4">
            {services.map((service, index) => (
              <Link
                key={index}
                to={`/services/${service.path}`}
                className={`block w-full text-left p-4 rounded-lg shadow-md flex justify-between items-center ${
                  activeService === service.name
                    ? "bg-primary text-white"
                    : "bg-white"
                }`}
              >
                <span className="text-lg font-semibold">{service.name}</span>
                <span className="text-lg">&rarr;</span>
              </Link>
            ))}
          </nav>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Claims;
