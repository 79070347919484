import { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 100 });
  }, []);

  return (
    <div className="border-b">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium">{title}</p>
        <svg
          viewBox="0 0 24 24"
          className={`w-3 text-gray-600 transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          <polyline
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            points="2,7 12,17 22,7"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  );
};

export const Faq = () => {
  return (
    <div
      className="px-4 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-1 lg:py-2 mb-8"
      data-aos="fade-up"
      data-aos-duration="500"
    >
      <div className="max-w-xl sm:mx-auto lg:max-w-4xl ">
        <div className="space-y-4">
          <Item
            title="What are the requirements for a successful immigration application?"
            className="mx-auto max-w-2xl text-center mb-10"
          >
            The requirements vary depending on the specific immigration program
            or visa you are applying for. We will guide you through the process
            and provide you with the necessary information.
          </Item>
          <Item
            title="How long does the immigration process usually take?"
            className="mx-auto max-w-2xl text-center mb-10"
          >
            The processing time can vary depending on the type of application
            and the specific circumstances. Our team will provide you with an
            estimate based on your situation.
          </Item>
          <Item
            title="What are the fees for your immigration consultancy services?"
            className="mx-auto max-w-2xl text-center mb-10"
          >
            Our fees depend on the specific services you require. We offer
            competitive pricing and transparent fee structures. Please contact
            us for more information.
          </Item>
          <Item
            title="Do you provide assistance with work permits and visas?"
            className="mx-auto max-w-2xl text-center mb-10"
          >
            Absolutely! We have experience in helping clients with work permits,
            visas, and other related immigration matters. Our team will guide
            you through the application process.
          </Item>
          <Item
            title="What is your success rate?"
            className="mx-auto max-w-2xl text-center mb-10"
          >
            Our success rate varies depending on the specific case and
            circumstances. We work diligently to provide the best possible
            representation and increase the chances of success for our clients.
          </Item>
          <Item
            title="How can I stay updated on changes in immigration policies and regulations?"
            className="mx-auto max-w-2xl text-center mb-10"
          >
            We regularly update our clients on changes in immigration policies
            and regulations through newsletters, email updates, and our
            website's news section. You can also reach out to us for the latest
            information.
          </Item>
        </div>
      </div>
    </div>
  );
};
