import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import data from "../utils/serviceItems";
import Aos from "aos";
import "aos/dist/aos.css";
import services from "../utils/Services";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet-async";

const Services = () => {
  useEffect(() => {
    Aos.init({ duration: 100 });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Services | AbaNorbert</title>
        <meta
          name="description"
          content="Explore the comprehensive immigration services offered by AbaNorbert Immigration Services Inc., designed to meet your unique needs and facilitate a smooth immigration process."
        />
        <link rel="canonical" href="/services" />
      </Helmet>

      <section>
        <div className="relative hero-container">
          <img
            src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_2000/canad12_x0rjzp"
            loading="lazy"
            className="hero-image"
          />
          <div className="absolute inset-0 bg-black opacity-70"></div>
          <div className="absolute inset-0 flex flex-col items-center justify-center text-white p-4">
            <h1 className="text-4xl font-bold text-center">
              OUR <span className="text-primary">SERVICES</span>
            </h1>
            <div className="h-1 w-20 bg-primary mt-2 mb-2"></div>
            <p className="text-xl text-center">
              AbaNorbert Immigration Services
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="w-full min-h-[90vh] bg-white" id="services">
          <div
            className="mx-auto max-w-2xl text-center py-10"
            data-aos="fade-up"
            data-aos-duration="300"
          >
            <h2 className="text-base font-semibold leading-7 text-primary">
              What we do
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our professional Services
            </p>
          </div>

          <div className="max-w-screen-xl mx-auto mt-5">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {services.map((item, index) => (
                <div
                  key={index}
                  className="rounded overflow-hidden flex flex-col transform transition-transform duration-300 hover:scale-105"
                >
                  <Link to={item.link}>
                    <img
                      className="w-full h-80 object-cover"
                      src={item.imgSrc}
                      loading="lazy"
                      alt={item.title}
                    />
                  </Link>
                  <div className="relative text-center -mt-16 pt-5 pb-12 bg-white m-10 shadow-lg rounded-lg">
                    <Link
                      to={item.link}
                      className="font-semibold text-lg inline-block hover:text-primary transition duration-500 ease-in-out text-center mt-3"
                    >
                      {item.title}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Services;
