const services = [
  {
    imgSrc: "https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_800/canada10_rnp2ws",
    title: "Refugee Claims",
    link: "/services/refugee-claims",
  },
  {
    imgSrc: "https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_800/canada13_suh0hl",
    title: "Refugee Appeal",
    link: "/services/refugee-appeal",
  },
  {
    imgSrc: "https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_800/canada16_rzmiot",
    title: "Pre-Removal Risk Assessment",
    link: "/services/pre-removal-risk-assessment",
  },
  {
    imgSrc: "https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_800/canada8_1_hmufch",
    title: "Humanitarian and Compassionate Consideration (H&C)",
    link: "/services/humanitarian-and-compassionate",
  },
  {
    imgSrc: "https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_800/canada17_f8rycm",
    title: "Detention Reviews",
    link: "/services/detention-reviews",
  },
  {
    imgSrc: "https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_800/canada19_k85qjp",
    title: "Express Entry",
    link: "/services/express-entry",
  },
  {
    imgSrc: "https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_800/canada18_fx7dpv",
    title: "Family Sponsorship",
    link: "/services/family-sponsorship",
  },

  {
    imgSrc: "https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_800/canada7_jsdthq",
    title: "Other Services",
    link: "/services/other-services",
  },
];

export default services;
