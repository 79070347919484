import { useState, useEffect } from 'react';

const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [color, setColor] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const changeColorAndVisibility = () => {
    // Change color based on scroll position
    if (window.scrollY >= 250) {
      setColor(true);
    } else {
      setColor(false);
    }

    // Show scroll-to-top button based on scroll position
    if (window.scrollY >= 200) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeColorAndVisibility);
    return () => {
      window.removeEventListener('scroll', changeColorAndVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return { color, showScrollToTop, scrollToTop };
};

export default useScroll;
