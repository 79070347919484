import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@stripe/stripe-js";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Services from "./Pages/Services";
import ContactUs from "./Pages/ContactUs";
import ErrorPage from "./Pages/404";
import Calendly from "./Components/Calendly";
import ScrollToTop from "./Components/ScrollToTop";
import TopHeader from "./Components/TopHeader";
import PayNow from "./Pages/PayNow";
import NavBar from "./Components/NavBar";
import Claims from "./Components/Claims";
import Success from "./Components/Success";
import Cancel from "./Components/Cancel";
import "./App.css";

const App = () => {
  return (
    <BrowserRouter>
      <TopHeader />
      <NavBar />
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="services" element={<Services />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="book-appointment" element={<Calendly />} />
        <Route path="pay-now" element={<PayNow />} />
        <Route path="services/refugee-claims" element={<Claims />} />
        <Route path="services/refugee-appeal" element={<Claims />} />
        <Route path="services/pre-removal-risk-assessment" element={<Claims />} />
        <Route path="services/humanitarian-and-compassionate-consideration" element={<Claims />} />
        <Route path="services/detention-reviews" element={<Claims />} />
        <Route path="services/family-sponsorship" element={<Claims />} />
        <Route path="services/express-entry" element={<Claims />} />
        <Route path="services/other-services" element={<Claims />} />
        <Route path="success" element={<Success />} />
        <Route path="cancel" element={<Cancel />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <ScrollToTop />
    </BrowserRouter>
  );
};

export default App;
