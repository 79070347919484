import React, { useEffect } from "react";
import "../Styles/AboutUs.css";
import Hero from "../Components/Hero";
import Footer from "../Components/Footer";
import Aos from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  useEffect(() => {
    Aos.init({ duration: 100 });
  }, []);

  return (
    <div>
      <Helmet>
        <title>About | AbaNorbert</title>
        <meta
          name="description"
          content="Learn about AbaNorbert Immigration Services Inc. and our mission to empower individuals through expert immigration guidance."
        />
        <link rel="canonical" href="/about-us" />
      </Helmet>

      <Hero />
      <section>
        <section className="flex flex-col md:flex-row justify-between items-center py-16 px-8 md:px-24 lg:px-32 bg-white">
          <div className="w-full md:w-1/2 custom-full-width">
            <h2
              className="text-2xl md:text-3xl font-bold text-gray-900 mb-4 custom-text-center md:text-left"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              ABOUT ABANORBERT IMMIGRATION SERVICES
            </h2>
            <div
              className="h-1 w-12 bg-primary mb-4 custom-divider-center md:mx-0"
              data-aos="fade-up"
              data-aos-duration="800"
            ></div>
            <p
              className="text-gray-700 mb-4"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              At AbaNorbert, we’re committed to providing personalized and
              reliable immigration services. We can guarantee that you will be
              well represented and protected when you choose AbaNorbert
              Immigration Services. We pride ourselves in providing Immigration
              lasting solutions to our customers. Let us be your trusted partner
              on your journey to Canada. Reach out to us today!
            </p>
            <p
              className="text-gray-700 mb-4"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              Our dedicated team, which features a Registered Canadian
              Immigration Consultant (RCIC), possesses extensive expertise in
              the most current immigration laws and regulations.
            </p>
            <p
              className="text-gray-700"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              Focusing on our clients' unique needs, we provide personalized
              assistance that considers individual circumstances, skills, and
              aspirations. We process Refugee Claims, Refugee Appeals, H&C
              Applications, Pre-Removal Risk Assessment, Detention reviews and
              other immigration applications.
            </p>
          </div>
          <div className="md:w-1/3 mt-8 md:mt-0 custom-hidden">
            <img
              src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_1000/abanlogo_wcfhdm"
              alt="logo"
              className="w-2/3 mx-auto md:mx-0"
            />
          </div>
        </section>

        <section className="flex flex-col md:flex-row justify-between items-center py-16 px-8 md:px-24 lg:px-32">
          <div className="md:w-1/3 mt-8 md:mt-0">
            <img
              src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_1000/norbert1_bagmvp"
              alt="Norbert"
              className="w-full rounded-lg shadow-lg border-2 border-gray-300 transform transition-transform duration-300 hover:scale-105 hover:shadow-xl"
            />
          </div>

          <div className="md:w-1/2 change">
            <h2
              className="text-base font-semibold leading-7 text-primary custom-hidden"
              data-aos="fade-left"
              data-aos-duration="800"
            >
              CEO
            </h2>
            <h2
              className="text-2xl md:text-4xl font-bold text-gray-900 mb-4 custom-text-center md:text-left"
              data-aos="fade-left"
              data-aos-duration="800"
            >
              MEET OUR RCIC
            </h2>
            <div
              className="h-1 w-12 bg-primary mb-4 custom-divider-center md:mx-0"
              data-aos="fade-left"
              data-aos-duration="800"
            ></div>
            <p
              className="text-gray-700 mb-4"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              AbaNorbert Immigration Services was established by Norbert, a
              regulated Canadian Immigration Consultant (RCIC-IRB), and a member
              in good standing with the College of Immigration and Citizenship
              Consultants (CICC). He brings extensive knowledge and expertise in
              Canadian Citizenship and Immigration Law, and a deep understanding
              of the international perspective.
            </p>
            <p
              className="text-gray-700 mb-4"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              Norbert completed his undergraduate studies from St. Olaf College
              in Minnesota, USA, and continued his graduate studies at Queens
              University in Canada where he obtained a Graduate Diploma in
              Canadian Immigration and Citizenship Law.
            </p>
            <p
              className="text-gray-700 mb-4"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              He aspires to empower people focusing on immigrant communities in
              Canada. His immigration practice is his best way to give back to
              community, and this stands as a unique passion for him.
            </p>
          </div>
        </section>

        {/* <section className="parallax">
        </section> */}
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
